/* HEADER AND FOOTER */

.fixed-header, .fixed-footer{
  width: 100%;
  position: fixed;        
  background: #333;
  color: #fff;
  z-index: 2;
}
.fixed-header{
  top: 0;
}
.fixed-footer{
  bottom: 0;
}

#companyName:hover {
  cursor: pointer;
}

#show-more-or-less:hover {
  cursor: pointer;
}

.swiper-slide-image:hover {
  filter: brightness(1.15);
  cursor: pointer;
}

/* END HEADER AND FOOTER */

body{        
  padding-top: 70px;
  padding-bottom: 200px;
  background-color: white;
}

/* App.js */

.dictionary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem auto;
  max-width: 90%;
}

.dictionary > .term {
  z-index: 1;
  text-align: center;
  /* max-width: 20rem; */
  margin-bottom: 3rem;
  /* padding: 2rem; */
  border-radius: 3px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all 100ms ease-in-out;
}

.dictionary > .term:hover {
  filter: brightness(1.15);
  cursor: pointer;
}

.dictionary > .term > dt {
  margin-bottom: 1rem;
}

dt {
  color: #2ec2b0;
  line-height: 1;
}

dt span {
  font-size: 200%;
  font-weight: 900;
}

dd {
  font-size: 52.5%;
  margin-left: 0;
  max-width:200px;
  word-wrap:break-word;
  top: 50%;
  left: 50%;
  transform: translate(5%, 0%);
}

dd dt {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 600;
  font-size: inherit;
}

dd dl {
  font-size: 90%;
  margin: 1rem auto 0 auto;
  letter-spacing: 0.33px;
  max-width: 15rem;
}

dd dl .term {
  margin-bottom: 1rem;
}

.term{
  animation-duration: 1s;
  animation-name: slidein;
}

/* end APP.js */



/* MEDIA QUERIES */

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .dictionary {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .dictionary {
    display: flex;
    justify-content: space-between;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 400px) {
  .dictionary {
    display: flex;
    /* justify-content: space-between; */
    flex-basis: 33%;
  }

  .fixed-footer{
    display: none;
  }

}

/* END MEDIA QUERIES */


/* CONTEXT MENU */

.react-contextmenu{
  z-index: 999 !important;
  position: fixed;
  min-width: 160px;
  padding: 5px 5px;
  margin: 2px 0 0;
  font-size: 16px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
    cursor: pointer;
}

/* END CONTEXT MENU */


/* KEYFRAMES */

@keyframes slidein {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

/* END KEYFRAMES */


/* MISC */

.centred{
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 70vw
}

.avatarImage {
  border-radius: 50%;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-75%, -50%);
  color: black;
  font-size: 20px;
}

.content-drop-zone:active {
  filter: brightness(1.15);
  cursor: move;
}
/* END MISC */